import React from 'react';
import Styled from 'styled-components';

const StyledMain = Styled.main`
    margin:0;
    padding:0;
    width:100%;
    height:auto;
    display:flex;
`;

const StyledMainOuter = Styled.div`
    margin:auto;
    height:80vh;
    display:flex;
    // min-height:600px;
    // height:calc(100vh - 185px);
    // max-width:1200px;
`;

const StyledMainInner = Styled.div`
    // margin:auto;
    width:100%;
    padding: 0 20%;
    display:flex;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0 3%;
    }

`;
const CoverText = Styled.h1`
  margin:0;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 1px;
  color:#363636;
  transform: translateY(-50%); 
  @media (max-width: 768px) {
    transform: translateY(0); /* Centered vertically for smaller screens */
  }

`;

const DocxorMain = () => {
    return(
        <StyledMain>
            <StyledMainOuter>
                <StyledMainInner>
                    <CoverText>We are building solutions to save lives by predicting medical emergencies before they happen.</CoverText>
                </StyledMainInner>
            </StyledMainOuter>
        </StyledMain>
    );
};
export default DocxorMain;