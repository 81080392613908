import React from 'react';
import Styled from 'styled-components';
import Logo from '../../component/Logo'

const StyledHeader = Styled.header`
    margin:0;
    padding:0;
    width:100%;
    height:auto;
`;

const StyledHeaderInner = Styled.div`
    margin:0;
    padding:0 3%;
`;

const StyledNav= Styled.nav`
    margin:auto;
    padding:0;
    width:100%;
    height:100px;
    display:flex;
`;


const DocxorHeader = () => {
    return(
            <StyledHeader>
                <StyledHeaderInner>
                    <StyledNav>
                        <Logo/>
                    </StyledNav>
                </StyledHeaderInner>
            </StyledHeader>
        );
};
export default DocxorHeader;