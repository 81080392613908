import {ThemeProvider} from 'styled-components';
import {GlobalStyle, LIGHT} from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Docxor from "./pages/Docxor";

const App = () => {
    return(
      <ThemeProvider theme = {LIGHT}>
        <GlobalStyle/>
        <Router>
          <Routes>
            <Route exact path="/" element={<Docxor/>} />
          </Routes>
        </Router>
      </ThemeProvider> 
    )
}
export default App;
