import React from "react";
import Styled from 'styled-components';
import Header from '../parts/header/DocxorHeader'
import Main from '../parts/main/DocxorMain'; 
import Footer from '../parts/footer/DocxorFooter';   
import BackgroundImage from '../asset/bg.png'

const StyledBackground = Styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
`;

const Docxor = () => {
    return (
        <React.Fragment>
            <StyledBackground>
                <Header />
                <Main />
                <Footer />
            </StyledBackground>
        </React.Fragment>
    )
}

export default Docxor;